/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Navbar from "./navbar"

import EmailConfirmation from "../../../components/user/emailconfirmation"

const LayoutMinimal = (props) => {

  const data = useStaticQuery(graphql`query SiteTitleQueryMinimal {
  site {
    siteMetadata {
      title
    }
  }
  sib_logo_bw: file(relativePath: {eq: "home-launch-demo.png"}) {
    childImageSharp {
      gatsbyImageData(height: 25, layout: FIXED)
    }
  }
}
`)

  return (
    <>
      {/* Wrapper for sticky footer */}
      <div className="site">
        <Navbar
          hideAllLinks={true}
          siteTitle={data.site.siteMetadata.title}
          logo={data.sib_logo_bw.childImageSharp}
        />
        <EmailConfirmation/>
        <main className="site-content">{props.children}</main>
      </div>
    </>
  )
}

LayoutMinimal.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutMinimal
