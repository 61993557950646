import React, { useState } from "react"
import { useIdentityContext } from "../../services/react-netlify-identity-gotrue"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"
import { useSubInfoContext } from "../../services/subinfocontext"

import Loader from "react-loader-spinner"

export default function ResetPasswordForm({ navigateTarget }) {
  const identity = useIdentityContext()
  const subInfoContext = useSubInfoContext()
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ reValidateMode: "onSubmit" })

  const [formError, setFormError] = useState(false)
  const [signingUp, setSigningUp] = useState(false)
  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState("")
  const [pwRelogin, setPwRelogin] = useState("")

  const onSubmit = async (data) => {
    setSigningUp(true)
    setFormError(false)
    const emailParsed = identity && identity.user && identity.user.email

    setEmail(emailParsed)
    setPwRelogin(data.password)

    identity
      .update({ password: data.password })
      .then(() => {
        setSigningUp(false)
        setSuccess(true)
      })
      .catch((e) => {
        setFormError(e.message)
        setSigningUp(false)
      })
  }

  const reLogin = (e) => {
    e.preventDefault()
    identity
      .logout()
      .then(() => {
        subInfoContext.logout()
        identity
          .login({
            email: email,
            password: pwRelogin,
          })
          .then(() => {
            navigate("/app/userhome")
          })
          .catch((e) => {
            setFormError(e.message)
            setSigningUp(false)
          })
      })
      .catch((e) => {
        setFormError(e.message)
        setSigningUp(false)
      })
  }

  const passwordConfirmValidate = () => {
    // Normal validation
    let password = getValues("password")
    let passwordConfirm = getValues("password_confirm")
    if (password === passwordConfirm) {
      return true
    } else {
      return "Passwords must match"
    }
  }

  return (
    <>
      {formError && (
        <div className="notification-error-text py-1">{formError}</div>
      )}
      <div className={`${signingUp || success ? "is-hidden" : ""}`}>
        <form id="password-reset-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="">Password</div>
          <div className="pb-1 is-size-7">Minimum 8 Characters</div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                className="input"
                {...register("password", {
                  required: true,
                  minLength: 8,
                  maxLength: 100,
                })}
                type="password"
                placeholder="Password"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-lock"></i>
              </span>
              <span className="notification-error-text">
                {errors.password && errors.password.type === "required" && (
                  <span>Password is required</span>
                )}
                {errors.password && errors.password.type === "maxLength" && (
                  <span>Max Length Exceeded (100)</span>
                )}
                {errors.password && errors.password.type === "minLength" && (
                  <span>Minimum 8 Characters</span>
                )}
              </span>
            </p>
          </div>
          <div className="pb-1">Confirm Password</div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                className="input"
                {...register("password_confirm", {
                  validate: passwordConfirmValidate,
                })}
                type="password"
                placeholder="Confirm Password"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-lock"></i>
              </span>
              <span className="notification-error-text">
                {errors.password_confirm && errors.password_confirm.message}
              </span>
            </p>
          </div>
          <button className="button is-black is-fullwidth mt-4" type="submit">
            Submit
          </button>
        </form>
      </div>
      <div className={`${signingUp ? "" : "is-hidden"}`}>
        <div className="has-text-centered my-2">
          <Loader type="TailSpin" color="#000000" height={100} width={100} />
        </div>
        <div className="title is-5 has-text-centered mt-3">
          Changing Password - A Moment, Please
        </div>
      </div>
      <div className={`${success ? "" : "is-hidden"}`}>
        <div className="container has-text-centered pt-6">
          <p>
            You have successfully changed your password.
          </p>
          <div className="block py-5 has-text-sibredorange">
            <a className="button is-black" href="/" onClick={e => reLogin(e) }>
              Continue
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
