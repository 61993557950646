import React, { useEffect } from "react"
import { useIdentityContext } from "../services/react-netlify-identity-gotrue"
import LayoutMinimal from "../components/common/layout/layoutminimal"
import ResetPasswordForm from "../components/user/resetpasswordform"
import { navigate } from "gatsby"

const SIBResetPasswordPage = ({ location }) => {
  const identity = useIdentityContext()

  // Redirect if we're here without a token based password recovery
  useEffect(() => {
    if (identity && identity.user) {
      if (
        !(
          identity.urlToken &&
          identity.urlToken.type === "passwordRecovery"
        )
      ) {
        navigate("/")
      }
    }
  }, [identity, identity.user])

  return (
    <LayoutMinimal>
      <SIBResetPasswordHeader />
      <div className="container user-login-form-container">
        <div className="columns">
          <div className="column is-8-desktop is-offset-2-desktop">
            <ResetPasswordForm />
          </div>
        </div>
      </div>
    </LayoutMinimal>
  )
}

const SIBResetPasswordHeader = (props) => {
  return (
    <>
      <section className="page-header bg-paper bg-tear-white">
        <div className="container">
          <div className="columns">
            <div className="column is-8-desktop is-offset-2-desktop">
              <h1>Password Change</h1>
              <div>Please enter a new password</div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SIBResetPasswordPage
